import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';

export const inqueryService = {
    newCount,
};

function newCount() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/inquery/new`;
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

