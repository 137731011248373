import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const inquiryService = {
    list,
    detail,
    store,
    statusUpdate,
    erase,
};

// 고객문의 > 목록
function list(type, params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/inquery/`+ type + `?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객문의 > 답변
function detail(type, id) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let addQuery = '';
    if (type == 'users' || type == 'webpartners') {
        addQuery = '/mail';
    }

    let url = getAdminUrl() + `/inquery/` + type + `/` + id + `/answer`+ addQuery;
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객문의 > 답변 저장
function store(type, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let addQuery = '';
    if (type == 'users' || type == 'webpartners') {
        addQuery = '/answer/mail';
    }

    let url = getAdminUrl() + `/inquery/` + type + addQuery + `/store`;
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객문의 > 상태변경
function statusUpdate(type, id, state, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/inquery/` + type + `/` + id + `/`+ state;
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객문의 > 삭제
function erase(type, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/inquery/` + type + `/delete`;
    return fetch(url, requestOptions).then(handleResponse);
}

