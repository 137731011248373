import {logService} from '@/helpers/service/log.service';

export const actions = {

    // 회원 > 조회 > 관계목록
    family({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.family(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 요청/승인
    accept({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.accept(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 이벤트
    event({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.event(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 안심귀가
    home({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.home(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 안심귀가
    zone({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.zone(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 채팅
    chat({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            logService.chat(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 구조요청
    help({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            logService.help(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 접속기록
    connect({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.connect(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 조회 > 위치열람기록
    location({dispatch}, {params}) {
        return new Promise((resolve, reject) => {
            logService.location(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {};

