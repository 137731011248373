import {apiHeader, baseHeader, handleResponse} from '../api-header';
import {getAdminUrl, getApiUrl} from '../api-config';
import store from "@/state/store";

export const authService = {
    login,
    logout,
    authCodeSend,
    authCodeVerify
};

function login(email, password) {

    let type = 'Admin';

    const requestOptions = {
        method: 'POST',
        headers: baseHeader(),
        body: JSON.stringify({ type, email, password })
    };

    return fetch(getApiUrl() + `/login`, requestOptions)
        .then(handleResponse)
        .then(result => {
            // console.log(result);
            // login successful if there's a jwt token in the response
            if (result.data.access_token) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                result.data.user['roles'] = result.data.roles;
                sessionStorage.setItem('user', JSON.stringify(result.data.user));
                sessionStorage.setItem('access_token', result.data.access_token);

                // 로그인과 동시에 카카오 인증코드를 전송한다
                authCodeSend().then( () =>
                    store.dispatch('notification/success', '카카오 인증코드가 전송되었습니다.', { root: true })
                )
            }
            return result.data;
        });
}

function logout() {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('super_verified');
    sessionStorage.removeItem('status');

    const requestOptions = {
        method: 'POST',
        headers: apiHeader()
    };

    return fetch(getApiUrl() + `/logout`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

function authCodeSend() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    return fetch(getAdminUrl() + `/super/auth/send`, requestOptions)
        .then(handleResponse)
        .then(result => {
            return result;
        });
}

function authCodeVerify(code) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/super/auth/verify?` + new URLSearchParams({ code }).toString();
    return fetch(url, requestOptions)
        .then(handleResponse)
        .then(result => {
            sessionStorage.setItem('super_verified', 'verified');
            return result;
        });
}
