import { userCounselService } from '@/helpers/service/user.counsel.service';

export const actions = {

    // 회원상담내역 > 목록
    list({ dispatch }, uid) {
        return new Promise((resolve, reject) => {
            userCounselService.list(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원상담내역 > 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            userCounselService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원상담내역 > 상태변경
    statusUpdate({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            userCounselService.statusUpdate(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원상담내역 > 삭제
    erase({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            userCounselService.erase(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

