import * as _ from 'lodash'
import Tablesaw from "tablesaw";

export const PAGE_COUNT = 10;

export function generateDataTableParams(params) {
    const defaultParams = {
        draw: '0',
        'columns[0][data]': 'created_at',
        'columns[0][orderable]': true,
        'order[0][column]': 0,
        'order[0][dir]': 'desc',
        search: [],
        start: 0,
        length: 10,
    };

    if(params.page) {
        let page = params.page;
        let start = page > 0? (page - 1) * PAGE_COUNT : 0;
        delete params.page;

        params = _.merge(params, {start : start});
    }

    return _.merge(defaultParams, params);
}

export function reloadTablesaw(element) {
    Tablesaw.$(element).data("tablesaw").destroy();
    Tablesaw.$(element).tablesaw();
}
