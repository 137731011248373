import {apiFileHeader, apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '@/helpers/api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const userService = {
    list,
    create,
    family,
    zones,
    points,
    recommends,
    recommendReset,
    memoChange,
    authSend,
    authComplete,
    store,
    changePassword,
    changeMyPassword,
    signout,
    restore,
    sendSearch,
    locations,
    familyZones
};

function list(type, params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/` + type + `?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function create(type, uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/` + (type == 'admins' ? type : 'users') + `/` + (uid ? uid +`/update`: 'create')
    return fetch(url, requestOptions).then(handleResponse);
}

function family(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/familys?`+ new URLSearchParams(params).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

function zones(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/zones?`+ new URLSearchParams(params).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

function points(uid) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/points/${uid}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function recommends(uid) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/referrals/${uid}`;
    return fetch(url, requestOptions).then(handleResponse);
}

function recommendReset(uid) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/${uid}/referral/reset`;
    return fetch(url, requestOptions).then(handleResponse);
}

function memoChange(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/memo/change`
    return fetch(url, requestOptions).then(handleResponse);
}

function authSend(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/access/send`
    return fetch(url, requestOptions).then(handleResponse);
}

function authComplete(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/auth/access/complete`
    return fetch(url, requestOptions).then(handleResponse);
}

function store(params) {
    let formData = new FormData();

    for ( let key in params ) {
        if(params[key] instanceof Array) {
            for ( let key2 in params[key] ) {
                formData.append(key+'[]', params[key][key2]);
            }
        }
        else formData.append(key, params[key]);
    }

    const requestOptions = {
        method: 'POST',
        headers: apiFileHeader(),
        body: formData
    };

    let url = getAdminUrl() + `/user/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 비밀번호 변경
function changePassword(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/change/password`
    return fetch(url, requestOptions).then(handleResponse);
}

// 관리자 본인 비밀번호 변경
function changeMyPassword(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/user/change/my/password`
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 탈퇴처리
function signout(type, uid, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/${type}/${uid}/signout`
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 복구처리
function restore(type, uid, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/${type}/${uid}/restore`
    return fetch(url, requestOptions).then(handleResponse);
}

// 전송 회원 검색
function sendSearch(params) {

    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/send/search?`+ new URLSearchParams(params).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 위치기록
function locations(uid, params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + '/user/'+uid+'/locations?' + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 부모목록
function familyZones(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + '/familyzones/'+uid+'/zone';
    return fetch(url, requestOptions).then(handleResponse);
}

