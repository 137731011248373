import {mapService} from '@/helpers/service/map.service';

export const actions = {

    // 회원 > 회원전환
    coordsToAddress({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            mapService.coordsToAddress(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {};

