import {apiHeader, handleResponse, apiFileHeader} from '../api-header';
import {getAdminUrl} from '../api-config';

export const seoService = {
    load,
    store,
};

// 설정 > seo
function load() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/seo`;
    return fetch(url, requestOptions).then(handleResponse);
}

// 설정 > seo > 저장
function store(params) {
    let formData = new FormData();
    for ( let key in params ) {
        if(params[key] instanceof Array) {
            for ( let key2 in params[key] ) {
                formData.append(key+'[]', params[key][key2]);
            }
        }
        else formData.append(key, params[key]);
    }

    const requestOptions = {
        method: 'POST',
        headers: apiFileHeader(),
        body: formData
    };
    let url = getAdminUrl() + `/seo/store`
    return fetch(url, requestOptions).then(handleResponse);
}
