import {userService} from '@/helpers/service/user.service';

export const actions = {

    // 회원 > 회원목록 > 일반회원
    list({dispatch}, {type, params}) {
        return new Promise((resolve, reject) => {
            userService.list(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 회원목록 > 회원등록
    create({dispatch}, {type, uid}) {
        return new Promise((resolve, reject) => {
            userService.create(type, uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 관계목록
    family({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.family(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 안심존
    zones({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.zones(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 포인트
    points({dispatch}, uid) {
        return new Promise((resolve, reject) => {
            userService.points(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 추천인 초기화
    recommendReset({dispatch}, uid) {
        return new Promise((resolve, reject) => {
            userService.recommendReset(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 추천인
    recommends({dispatch}, uid) {
        return new Promise((resolve, reject) => {
            userService.recommends(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 메모 저장
    memoChange({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.memoChange(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 인증요청
    authSend({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.authSend(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 인증 완료처리
    authComplete({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.authComplete(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 회원정보 저장
    store({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 비밀번호 변경
    changePassword({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.changePassword(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 본인 비밀번호 변경
    changeMyPassword({dispatch}, params) {
        return new Promise((resolve, reject) => {
            userService.changeMyPassword(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 탈퇴처리
    signout({dispatch}, {type, uid, params}) {
        return new Promise((resolve, reject) => {
            userService.signout(type, uid, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 회원 > 복구처리
    restore({dispatch}, {type, uid, params}) {
        return new Promise((resolve, reject) => {
            userService.restore(type, uid, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 전송 회원검색
    sendSearch({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            userService.sendSearch(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원 위치
    locations({ dispatch }, { uid, params }) {
        return new Promise((resolve, reject) => {
            userService.locations(uid, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 회원 부모목록
    familyZones({ dispatch }, uid) {
        return new Promise((resolve, reject) => {
            userService.familyZones(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {};

