export function getHostUrl() {
    return process.env.VUE_APP_API_URL
}
export function getSocketUrl() {
    if (process.env.VUE_APP_SOCKET_URL) {
        return process.env.VUE_APP_SOCKET_URL
    }
    return process.env.VUE_APP_API_URL
}
export function getApiUrl() {
    return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_API
}
export function getAdminUrl() {
    return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_ADMIN
}
export function getImageUrl(width, height, uid, baseImage) {
    if(!uid || 0 === uid.length) {
        if(baseImage) return baseImage
        else return require('@/assets/images/users/avatar-0.png')
    }
    return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_IMAGE + "/"+width+"x"+height+"/"+uid
}

export function getFileUrl(uid) {
    return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_FILE + "/" + uid
}

export function getImageRateUrl(rate, uid) {
    return process.env.VUE_APP_API_URL + "/image/rate/" + rate +"/"+ uid
}

export function getFileNameUrl(uid, name) {
    return process.env.VUE_APP_API_URL + process.env.VUE_APP_API_FILE + "/" + uid + "/" + name
}
