import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';

export const statisticsService = {
    dataAll,
    dataUserSignin
};

function dataAll() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/stats/data/all`;
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function dataUserSignin(type) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/stats/data/user-signin?type=` + type;
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}


