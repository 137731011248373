import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';

export const userCounselService = {
    list,
    store,
    statusUpdate,
    erase,
};

// 회원상담내역 > 목록
function list(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/${uid}/counsel`;
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원상담내역 > 저장
function store(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/counsel/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원상담내역 > 상태변경
function statusUpdate(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/counsel/status`
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원상담내역 > 삭제
function erase(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/counsel/delete`
    return fetch(url, requestOptions).then(handleResponse);
}

