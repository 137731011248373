import { appErrorService } from '@/helpers/service/app.error.service';

export const actions = {

    // 고객대응 > 앱문제신고
    list({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            appErrorService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 앱문제신고 > 상세
    detail({ dispatch }, uid) {
        return new Promise((resolve, reject) => {
            appErrorService.detail(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 앱문제신고 > 답변
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            appErrorService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 앱문제신고 > 상태변경
    statusUpdate({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            appErrorService.statusUpdate(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 앱문제신고 > 삭제
    erase({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            appErrorService.erase(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

