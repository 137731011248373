import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const messagesService = {
    push,
    sms,
    send,
    store, 
};

// 운영 > 푸시/문자 > 푸시 내역
function push(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/pushs?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 운영 > 푸시/문자 > 문자 내역
function sms(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/smss?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 운영 > 푸시/문자 > 푸시/문자 전송
function send() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/push/send`
    return fetch(url, requestOptions).then(handleResponse);
}

// 운영 > 푸시/문자 > 푸시/문자 저장
function store(params) {

    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/push/store`
    return fetch(url, requestOptions).then(handleResponse);
}

