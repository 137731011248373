import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const counselService = {
    list,
    store,
    statusUpdate,
    erase,
};

// 고객대응 > 고객상담관리
function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/counsel?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 고객상담관리 > 저장
function store(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/counsel/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 고객상담관리 > 상태변경
function statusUpdate(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/counsel/status`
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 고객상담관리 > 상태변경
function erase(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/counsel/delete`
    return fetch(url, requestOptions).then(handleResponse);
}

