import { createStore } from 'vuex'

import modules from './modules'

const store = createStore({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store

export const makeCancelablePromise = promise => {
  let hasCanceled = false
  const wrappedPromise = new Promise((resolve, reject) => {
    promise
        .then(val => (hasCanceled ? null : resolve(val)))
        .catch(
            error => (hasCanceled ? null : reject(error))
        )
  })

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true
    }
  }
}

