<template>
 <router-view :key="$route.path"></router-view>
</template>

<script>

import {apiInqueryMethods, authComputed} from "@/state/helpers";
import store from "@/state/store";
import io from "socket.io-client";
import {getSocketUrl} from "@/helpers/api-config";
import Echo from "laravel-echo";

export default {
  name: 'App',
  components: {
  },
  computed: {
    ...authComputed
  },
  methods: {
    ...apiInqueryMethods,
    loadInqueryCount() {
      // console.log("loggedIn : " + this.loggedIn)
      if(this.loggedIn === true) store.dispatch("apiInquerys/newCount")
    },
    connectEchoServer() {
      let access_token = sessionStorage.getItem('access_token');

      let options = {
        client: io,
        enabledTransports: ['ws', 'wss'],
        transports: ['websocket'],
        broadcaster: 'socket.io',
        host: getSocketUrl(),
        auth: {
          headers: {
            'Authorization': 'Bearer ' + access_token,
            // authEndpoint: '/web/broadcast/auth',
          }
        }
      }

      window.Echo = new Echo(options);
      console.log('Echo connect')
      window.Echo.private('App.Admin.Event').listen('.inquery.new', () => {
        this.loadInqueryCount()
      });
    },
    disconnectEchoServer() {
      console.log('Echo disconnect')
      window.Echo.disconnect()
      window.Echo = null
    }
  },
  watch: {
    loggedIn(newVal, oldVal) {
      if(newVal !== oldVal) {
        if (newVal===true) {
          if(window.Echo) {
            this.disconnectEchoServer()
          }
          this.connectEchoServer()
        } else {
          this.disconnectEchoServer()
        }
      }
    },
  },
  mounted() {
    this.loadInqueryCount();

    if(this.loggedIn === true) {
      if(window.Echo) {
        this.disconnectEchoServer()
      }
      this.connectEchoServer()
    }
  },
  unmounted() {
    this.disconnectEchoServer()
  }
}
</script>
