import { pointService } from '@/helpers/service/point.service';

export const actions = {

    // 운영 > 포인트 > 포인트 내역
    list({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            pointService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 운영 > 포인트 > 포인트 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            pointService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

