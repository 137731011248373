import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '@/helpers/api-config';

export const rolesService = {
    list,
    userRoles,
    store,
};

// 관리자 권한 > 권한 코드목록
function list() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/roles/list`;
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 관리자 권한 > 회원 권한목록
function userRoles(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/user/${uid}/roles`
    return fetch(url, requestOptions).then(handleResponse);
}

// 관리자 권한 > 권한 저장
function store(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/user/roles/store`
    return fetch(url, requestOptions).then(handleResponse);
}

