import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '@/helpers/api-config';

export const mapService = {
    coordsToAddress
};

// 좌표 -> 주소 변환
function coordsToAddress(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/coords/address`
    return fetch(url, requestOptions).then(handleResponse);
}
