import { chargeService } from '@/helpers/service/charge.service';

export const actions = {

    // 설정 > 약관관리 리스트
    list({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            chargeService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
    update({ dispatch }, {type, params}) {
        return new Promise((resolve, reject) => {
            chargeService.update(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

