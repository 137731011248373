import { createWebHistory, createRouter } from "vue-router";

// import store from '@/state/store'
import routes from './routes'
import store from "@/state/store";

const router = createRouter({
  history: createWebHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  // 페이지 이동시 항상 clear
  store.dispatch('notification/clear')

  // 인증 제외 페이지
  const publicPages = ['/login'];
  const authpage = !publicPages.includes(routeTo.path);
  const loggeduser = sessionStorage.getItem('user');
  const superVerified = sessionStorage.getItem('super_verified');

  // console.log('-------------------------');
  // console.log(authpage, loggeduser, superVerified);

  if (authpage && (!loggeduser || superVerified !== "verified")) {

    sessionStorage.removeItem('user');
    sessionStorage.removeItem('access_token');
    sessionStorage.removeItem('super_verified');

    return next('/login');
  }

  next();
})

export default router
