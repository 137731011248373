import { counselService } from '@/helpers/service/counsel.service';

export const actions = {

    // 고객대응 > 고객상담관리
    list({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            counselService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 고객상담관리 > 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            counselService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 고객상담관리 > 상태변경
    statusUpdate({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            counselService.statusUpdate(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 고객상담관리 > 삭제
    erase({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            counselService.erase(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

