import { helpActiveService } from '@/helpers/service/help.active.service';

export const actions = {
    list({ dispatch }) {
        return new Promise((resolve, reject) => {
            helpActiveService.list().then(
                data => {
                    resolve(data);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                    reject(error);
                }
            )
        })
    },

    // Logs in the user.
    users({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            helpActiveService.users(params).then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                )
        })
    },

    user({ dispatch }, { uid }) {
        return new Promise((resolve, reject) => {
            helpActiveService.user(uid).then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    helpComplete({ dispatch }, { uid, params }) {
        return new Promise((resolve, reject) => {
            helpActiveService.helpComplete(uid, params).then(
                data => {
                    resolve(data);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                    reject(error);
                }
            );
        })
    },
    locations({ dispatch }, { uid }) {
        return new Promise((resolve, reject) => {
            helpActiveService.locations(uid).then(
                data => {
                    resolve(data);
                },
                error => {
                    dispatch('notification/error', error, { root: true });
                    reject(error);
                }
            );
        })
    },
};

export const mutations = {

};

