import {apiHeader, handleResponse, apiFileHeader} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const bannerService = {
    list,
    load,
    store,
    erase,
};

// 설정 > 배너리스트
function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/banners?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 설정 > 배너리스트 > 수정
function load(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/banner/` + uid + `/update`
    return fetch(url, requestOptions).then(handleResponse);
}

// 설정 > 배너리스트 > 저장
function store(params) {
    let formData = new FormData();
    for ( let key in params ) {
        if(params[key] instanceof Array) {
            for ( let key2 in params[key] ) {
                formData.append(key+'[]', params[key][key2]);
            }
        }
        else formData.append(key, params[key]);
    }

    const requestOptions = {
        method: 'POST',
        headers: apiFileHeader(),
        body: formData
    };
    let url = getAdminUrl() + `/banner/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 설정 > 배너리스트 > 삭제
function erase(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/banner/delete`
    return fetch(url, requestOptions).then(handleResponse);
}

