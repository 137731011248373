import { statisticsService } from '@/helpers/service/statistics.service';

export const actions = {

    // Logs in the user.
    dataAll({ dispatch }) {

        return new Promise((resolve, reject) => {
            statisticsService.dataAll()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    // Logs in the user.
    dataUserSignin({ dispatch }, { type }) {

        return new Promise((resolve, reject) => {
            statisticsService.dataUserSignin(type)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },
};

export const mutations = {

};

