import { menuItems } from "@/components/menu";

const methods = {
    changeMenu(id, code) {
        let menuId = id ? id : 100100
        let menu = menuItems.find(v => v.id == menuId)
        if (code && menu.subItems) {
            menu = menu.subItems.find(v => v.code == code)
        }
        if (typeof menu != 'undefined') {
            this.$store.dispatch("layout/changeMenu", menu.id)
//            console.log(menu)
        }
    },
    nl2br(str) {
        if (!str){
            return ''
        }
        return str.replace(/\r\n|\n/g, '<br />')
    },
    formatPhone(str, separator = '-') {
        if (!str){
            return ''
        }
        return str.replace(/(^02.{0}|^01.{1}|[0-9]{3})([0-9]+)([0-9]{4})/,"$1"+ separator +"$2"+ separator +"$3")
    }
}

export default {
    install(Vue) {
        Vue.config.globalProperties.$changeMenu = methods.changeMenu;
        Vue.config.globalProperties.$nl2br = methods.nl2br;
        Vue.config.globalProperties.$formatPhone = methods.formatPhone;
    }
}