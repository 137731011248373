import { messagesService } from '@/helpers/service/messages.service';

export const actions = {

    // 운영 > 푸시/문자 > 푸시 내역
    push({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            messagesService.push(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 운영 > 푸시/문자 > 문자 내역
    sms({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            messagesService.sms(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 운영 > 푸시/문자 > 푸시/문자 전송
    send({ dispatch }) {
        return new Promise((resolve, reject) => {
            messagesService.send()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 운영 > 푸시/문자 > 푸시/문자 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            messagesService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

