import { boardService } from '@/helpers/service/board.service';

export const actions = {

    // Logs in the user.
    list({ dispatch }, { type, params }) {

        return new Promise((resolve, reject) => {
            boardService.list(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    create({ dispatch }, { code, uid }) {

        return new Promise((resolve, reject) => {
            boardService.create(code, uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            boardService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    assetDelete({ dispatch }, { params }) {

        return new Promise((resolve, reject) => {
            boardService.assetDelete(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    del({ dispatch }, { code, params }) {

        return new Promise((resolve, reject) => {
            boardService.del(code, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },
};

export const mutations = {

};

