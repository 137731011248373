import { versionService } from '@/helpers/service/version.service';

export const actions = {

    // 설정 > version 가져오기
    load({ dispatch }) {
        return new Promise((resolve, reject) => {
            versionService.load()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > 버전관리 > 저장
    store({ dispatch }, { url, params }) {
        return new Promise((resolve, reject) => {
            versionService.store(url, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

