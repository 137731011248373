import store from "@/state/store";

export default [
    {
        path: "/",
        name: "default",
        meta: {authRequired: true},
        component: () => import("./views/cms/default"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import("./views/account/login"),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => () => {
            store.dispatch("apiAuth/logout");
        },
    },
    {
        path: "/forgot-password",
        name: "Forgot password",
        component: () => import("./views/account/forgot-password"),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters["auth/loggedIn"]) {
                    // Redirect to the home page instead
                    next({name: "default"});
                } else {
                    // Continue to the login page
                    next();
                }
            },
        },
    },
    {
        path: "/logout",
        name: "logout",
        component: () => import("./views/account/logout"),
        meta: {
            authRequired: true,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch("apiAuth/logout");

                const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
                    route.push("/login")
                );
                // Navigate back to previous page, or home as a fallback
                next(
                    authRequiredOnPreviousRoute ? {name: "default"} : {...routeFrom}
                );
            },
        },
    },

    /* 에러 페이지 */
    {
        path: "/404",
        name: "404",
        component: require("./views/pages/404").default,
    },
    {
        path: "/pages/404",
        name: "error-404",
        meta: {authRequired: true},
        component: () => import("./views/pages/404"),
    },
    {
        path: "/pages/500",
        name: "error-500",
        meta: {authRequired: true},
        component: () => import("./views/pages/500"),
    },
    {
        path: "/pages/coming-soon",
        name: "coming-soon",
        meta: {authRequired: true},
        component: () => import("./views/pages/coming-soon"),
    },
    // Redirect any unmatched routes to the 404 page. This may
    // require some server configuration to work in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    // {
    //   path: "*",
    //   redirect: "404",
    // },

    /* 회원 */
    {
        // 회원목록
        path: "/users",
        name: "users",
        meta: {authRequired: true},
        component: () => import("./views/cms/users/index"),
    },
    {
        // 회원검색
        path: "/users/search",
        name: "users-search",
        meta: {authRequired: true},
        component: () => import("./views/cms/users/search"),
    },
    {
        // 관리자 목록
        path: '/users/admin',
        name: "users-admin",
        meta: {authRequired: true},
        component: () => import("./views/cms/users/admin"),
    },
    {
        // 회원상세
        path: "/users/:type/:uid",
        name: "users-detail",
        meta: {authRequired: true},
        component: () => import("./views/cms/users/detail"),
    },
    {
        // 회원등록
        path: '/users/:type/create',
        name: "users-create",
        meta: {authRequired: true},
        component: () => import("./views/cms/users/detail"),
    },

    /* 구조요청 */
    {
        // 구조요청 목록
        path: "/help",
        name: "help",
        meta: {authRequired: true},
        component: () => import("./views/cms/help/index"),
    },
    {
        // 구조상황판
        path: "/help/active",
        name: "help-active",
        meta: {authRequired: true},
        component: () => import("./views/cms/help/active"),
    },

    /* 앱문제신고 */
    {
        // 앱문제신고 목록
        path: "/app/error",
        name: "app-error",
        meta: {authRequired: true},
        component: () => import("./views/cms/apperror/index"),
    },
    {
        // 앱문제신고 상세
        path: "/app/error/:uid",
        name: "app-error-detail",
        meta: {authRequired: true},
        component: () => import("./views/cms/apperror/detail"),
    },

    /* 고객문의 */
    {
        // 고객문의 목록(앱)
        path: "/inquiry/app/:type",
        name: "inquiry-app",
        meta: {authRequired: true},
        component: () => import("./views/cms/inquiry/app/index"),
    },
    {
        // 앱 고객문의 답변(앱)
        path: "/inquiry/app/:type/:id",
        name: "inquiry-app-detail",
        meta: {authRequired: true},
        component: () => import("./views/cms/inquiry/app/detail"),
    },
    {
        // 고객문의 목록(홈페이지)
        path: "/inquiry/home/:type",
        name: "inquiry-home",
        meta: {authRequired: true},
        component: () => import("./views/cms/inquiry/home/index"),
    },
    {
        // 고객문의 답변(홈페이지)
        path: "/inquiry/home/:type/:id",
        name: "inquiry-home-detail",
        meta: {authRequired: true},
        component: () => import("./views/cms/inquiry/home/detail"),
    },
    {
        // 고객문의 목록(증거자료요청)
        path: "/inquiry/evidences",
        name: "inquiry-evidence",
        meta: {authRequired: true},
        component: () => import("./views/cms/inquiry/app/evidence"),
    },

    /* 고객상담내역 */
    {
        // 고객상담 목록
        path: "/counsel",
        name: "counsel",
        meta: {authRequired: true},
        component: () => import("./views/cms/counsel/index"),
    },

    /* 조회 */
    {
        path: "/log",
        name: "log",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/family"),
    },
    {
        // 관계 목록
        path: "/log/family",
        name: "log-family",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/family"),
    },
    {
        // 관계,구조요청 - 요청/승인 내역
        path: "/log/accept",
        name: "log-accept",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/accept"),
    },
    {
        // 구조요청,안심존,안심귀가 이벤트 내역
        path: "/log/event",
        name: "log-event",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/event"),
    },
    {
        // 안심귀가 목록
        path: "/log/home",
        name: "log-home",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/home"),
    },
    {
        // 안심존 목록
        path: "/log/zone",
        name: "log-zone",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/zone"),
    },
    {
        // 채팅 목록
        path: "/log/chat",
        name: "log-chat",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/chat"),
    },
    {
        // 접속기록
        path: "/log/connect",
        name: "log-connect",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/connect"),
    },
    {
        // 위치조회기록
        path: "/log/location",
        name: "log-location",
        meta: {authRequired: true},
        component: () => import("./views/cms/log/location"),
    },

    /* 게시판 */
    {
        // 게시판 목록
        path: "/bbs/:code",
        name: "bbs",
        meta: {authRequired: true},
        component: () => import("./views/cms/bbs/index"),
    },
    {
        // 게시물 등록
        path: "/bbs/:code/create",
        name: "bbs-create",
        meta: {authRequired: true},
        component: () => import("./views/cms/bbs/create"),
    },
    {
        // 게시물 수정
        path: "/bbs/:code/:uid",
        name: "bbs-modify",
        meta: {authRequired: true},
        component: () => import("./views/cms/bbs/create"),
    },

    /* 푸시/문자 */
    {
        // 메시지 전송
        path: "/messages",
        name: "messages",
        meta: {authRequired: true},
        component: () => import("./views/cms/messages/index"),
    },
    {
        // 푸시 전송내역
        path: "/messages/push",
        name: "messages-push",
        meta: {authRequired: true},
        component: () => import("./views/cms/messages/push"),
    },
    {
        // 문자 전송내역
        path: "/messages/sms",
        name: "messages-sms",
        meta: {authRequired: true},
        component: () => import("./views/cms/messages/sms"),
    },

    /* 포인트 */
    {
        // 포인트 내역
        path: "/point",
        name: "point",
        meta: {authRequired: true},
        component: () => import("./views/cms/point/index"),
    },
    {
        // 포인트 지급
        path: "/point/give",
        name: "point-give",
        meta: {authRequired: true},
        component: () => import("./views/cms/point/give"),
    },

    /* 쿠폰 */
/*
    {
        // 마스터쿠폰 목록
        path: "/coupon",
        name: "coupon",
        meta: {authRequired: true},
        component: () => import("./views/cms/coupon/index"),
    },
    {
        // 사용자쿠폰 목록
        path: "/coupon/user",
        name: "coupon-user",
        meta: {authRequired: true},
        component: () => import("./views/cms/coupon/user"),
    },
    {
        // 마스터쿠폰 생성
        path: "/coupon/create",
        name: "coupon-create",
        meta: {authRequired: true},
        component: () => import("./views/cms/coupon/create"),
    },
    {
        // 마스터쿠폰 수정
        path: "/coupon/create/:uid",
        name: "coupon-modify",
        meta: {authRequired: true},
        component: () => import("./views/cms/coupon/create"),
    },
*/
    /* 결제 */
/*
    {
        // 결제내역
        path: "/payment",
        name: "payment",
        meta: {authRequired: true},
        component: () => import("./views/cms/payment/index"),
    },
*/
    /* 설정 */
    {
        path: "/setting",
        name: "setting",
        meta: {authRequired: true},
        component: () => import("./views/cms/terms/index"),
    },
    {
        // 약관 목록
        path: "/setting/terms",
        name: "terms",
        meta: {authRequired: true},
        component: () => import("./views/cms/terms/index"),
    },
    {
        // 약관 등록
        path: "/setting/terms/create",
        name: "terms-create",
        meta: {authRequired: true},
        component: () => import("./views/cms/terms/create"),
    },
    {
        // 약관 수정
        path: "/setting/terms/:uid",
        name: "terms-modify",
        meta: {authRequired: true},
        component: () => import("./views/cms/terms/create"),
    },
    {
        // 구조그룹 목록
        path: "/setting/group",
        name: "setting-group",
        meta: {authRequired: true},
        component: () => import("./views/cms/setting/group"),
    },
    {
        // 지역 목록
        path: "/setting/area",
        name: "setting-area",
        meta: {authRequired: true},
        component: () => import("./views/cms/setting/area"),
    },
    {
        // 배너 목록
        path: "/setting/banners/:type",
        name: "banner",
        meta: {authRequired: true},
        component: () => import("./views/cms/banner/index"),
    },
    {
        // 배너 등록
        path: "/setting/banners/:type/create",
        name: "banner-create",
        meta: {authRequired: true},
        component: () => import("./views/cms/banner/create"),
    },
    {
        // 배너 수정
        path: "/setting/banners/:type/:uid",
        name: "banner-modify",
        meta: {authRequired: true},
        component: () => import("./views/cms/banner/create"),
    },
    {
        // 월정액
        path: "/setting/charge",
        name: "setting-charge",
        meta: {authRequired: true},
        component: () => import("./views/cms/setting/charge"),
    },
    {
        // 홈페이지 SEO
        path: "/setting/seo",
        name: "setting-seo",
        meta: {authRequired: true},
        component: () => import("./views/cms/setting/seo"),
    },
    {
        // 앱버전
        path: "/setting/version",
        name: "setting-version",
        meta: {authRequired: true},
        component: () => import("./views/cms/setting/version"),
    },

    /* 통계 */
/*
    {
        // 통계 메인
        path: "/stats",
        name: "stats",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/index"),
    },
    {
        // 회원 통계
        path: "/stats/user",
        name: "stats-user",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/user"),
    },
    {
        // 구조요청 통계
        path: "/stats/help",
        name: "stats-help",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/help"),
    },
    {
        // 접속 통계
        path: "/stats/connect",
        name: "stats-connect",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/connect"),
    },
    {
        // 운영체제 통계
        path: "/stats/device",
        name: "stats-device",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/device"),
    },
    {
        // 지역별 통계
        path: "/stats/area",
        name: "stats-area",
        meta: {authRequired: true},
        component: () => import("./views/cms/statistics/area"),
    },
*/
];