import {authService} from "@/helpers/service/auth.service";

export function baseHeader(options) {
    let baseHeader = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    };
    return { ...baseHeader, ...options };
}

export function apiHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));
    let access_token = sessionStorage.getItem('access_token');
    // console.log(access_token);
    if (user && access_token) {
        return baseHeader({ 'Authorization': 'Bearer ' + access_token });
    } else {
        return baseHeader({});
    }
}
export function apiFileHeader() {
    // return authorization header with jwt token
    let user = JSON.parse(sessionStorage.getItem('user'));
    let access_token = sessionStorage.getItem('access_token');
    // console.log(access_token);
    if (user && access_token) {
        return {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + access_token,
            'X-Requested-With': 'XMLHttpRequest'
        };
    } else {
        return baseHeader({});
    }
}

export function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                // auto logout if 401 response returned from api
                authService.logout();
                location.reload(true);
            }
            let error = (data && data.message) || response.statusText;
            // console.log(Object.values(data.errors));
            if(data.errors && Object.values(data.errors).length > 0) error = Object.values(data.errors)[0][0];
            return Promise.reject(error);
        }
        return data;
    });
}