import { inquiryService } from '@/helpers/service/inquiry.service';

export const actions = {

    // 고객문의 > 목록
    list({ dispatch }, { type, params }) {
        return new Promise((resolve, reject) => {
            inquiryService.list(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객문의 > 답변하기
    detail({ dispatch }, { type, id }) {
        return new Promise((resolve, reject) => {
            inquiryService.detail(type, id)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객문의 > 답변 저장
    store({ dispatch }, { type, params }) {
        return new Promise((resolve, reject) => {
            inquiryService.store(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객문의 > 상태변경
    statusUpdate({ dispatch }, { type, id, state, params }) {
        return new Promise((resolve, reject) => {
            inquiryService.statusUpdate(type, id, state, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객문의 > 삭제
    erase({ dispatch }, { type, params }) {
        return new Promise((resolve, reject) => {
            inquiryService.erase(type, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

