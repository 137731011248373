import { inqueryService } from '@/helpers/service/inquery.service';

const inqueryNewCount = JSON.parse(sessionStorage.getItem('inqueryNewCount'));
export const state = inqueryNewCount
    ? { inqueryNewCount }
    : { inqueryNewCount : null };

export const getters = {
    inqueryNewCount(state) {
        return state.inqueryNewCount
    }
}

export const actions = {

    // Logs in the user.
    newCount({ dispatch, commit }) {

        return new Promise((resolve, reject) => {
            inqueryService.newCount()
                .then(
                    data => {
                        commit('inqueryNewCount', data.data);
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },
};

export const mutations = {
    inqueryNewCount(state, data) {
        state.inqueryNewCount = data
    },
};

