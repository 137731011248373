export const menuItems = [
    {
        id: 100100,
        code: "default",
        role: [],
        label: "sidemenu.main.items.home",
        icon: "bx-home",
        link: "/",
    },
    {
        id: 100200,
        code: "help-active",
        role: ["admin:help"],
        label: "sidemenu.main.items.help",
        icon: "bx-street-view",
        link: "/help/active",
    },
    {
        // 회원
        id: 200000,
        code: "users",
        role: ["admin:users", "admin:admin-users"],
        label: "sidemenu.users.text",
        link: "/users/search",
        isTitle: true,
    },
    {
        // 회원검색
        id: 200100,
        code: "users-search",
        role: ["admin:users"],
        label: "sidemenu.users.items.search",
        icon: "bx-id-card",
        link: "/users/search"
    },
    {
        // 회원목록
        id: 200200,
        label: "sidemenu.users.items.list",
        code: "users-list",
        role: ["admin:users"],
        icon: "bx-group",
        link: "/users",
        subItems: [
            {
                id: 200201,
                code: "user",
                role: ["admin:users"],
                label: "sidemenu.users.items.user",
                link: "/users",
                parentId: 200200
            },
            {
                id: 200202,
                code: "admin",
                role: ["admin:admin-users"],
                label: "sidemenu.users.items.admin",
                link: "/users/admin",
                parentId: 200200
            }
        ]
    },
    {
        // 조회
        id: 200300,
        code: "log",
        role: ["admin:search", "admin:search-chat", "admin:help", "admin:log-user", "admin:log-location"],
        label: "sidemenu.log.text",
        icon: "bx-layer",
        link: "/log",
        subItems: [
            {
                id: 200301,
                code: "family",
                role: ["admin:search"],
                label: "sidemenu.log.items.family",
                link: "/log",
                parentId: 200300
            },
            {
                // 요청/승인내역(관계,구조요청)
                id: 200302,
                code: "accept",
                role: ["admin:search"],
                label: "sidemenu.log.items.accept",
                link: "/log/accept",
                parentId: 200300
            },
            {
                // 이벤트(안심존,안심귀가,구조요청)
                id: 200303,
                code: "event",
                role: ["admin:search"],
                label: "sidemenu.log.items.event",
                link: "/log/event",
                parentId: 200300
            },
            {
                // 안심귀가
                id: 200304,
                code: "home",
                role: ["admin:search"],
                label: "sidemenu.log.items.home",
                link: "/log/home",
                parentId: 200300
            },
            {
                // 안심존 목록
                id: 200305,
                code: "zone",
                role: ["admin:search"],
                label: "sidemenu.log.items.zone",
                link: "/log/zone",
                parentId: 200300
            },
            {
                // 채팅
                id: 200306,
                code: "chat",
                role: ["admin:search-chat"],
                label: "sidemenu.log.items.chat",
                link: "/log/chat",
                parentId: 200300
            },
            {
                // 구조요청
                id: 200307,
                code: "help",
                role: ["admin:help"],
                label: "sidemenu.log.items.help",
                link: "/help",
                parentId: 200300
            },
            {
                // 접속기록
                id: 200308,
                code: "connect",
                role: ["admin:log-user"],
                label: "sidemenu.log.items.connect",
                link: "/log/connect",
                parentId: 200300
            },
            {
                // 위치열람기록
                id: 200309,
                code: "location",
                role: ["admin:log-location"],
                label: "sidemenu.log.items.location",
                link: "/log/location",
                parentId: 200300
            }
        ]
    },
    {
        // 고객대응
        id: 300000,
        code: "customer",
        role: ["admin:app-errors", "admin:inquiry", "admin:counsel"],
        label: "sidemenu.customer.text",
        link: "/app/error",
        isTitle: true
    },
    {
        // 앱문제신고
        id: 300100,
        code: "app-error",
        role: ["admin:app-errors"],
        label: "sidemenu.customer.items.errors",
        icon: "bx-error-circle",
        link: "/app/error"
    },
    {
        // 고객문의
        id: 300200,
        code: "inquiry",
        role: ["admin:inquiry"],
        label: "sidemenu.customer.items.inquiry",
        icon: "bx-chat",
        link: "/inquiry/app/apps",
        subItems: [
            {
                id: 300201,
                code: "apps",
                role: ["admin:inquiry"],
                label: "sidemenu.customer.items.apps",
                link: "/inquiry/app/apps",
                parentId: 300200
            },
            {
                id: 300202,
                code: "partners",
                role: ["admin:inquiry"],
                label: "sidemenu.customer.items.partner",
                link: "/inquiry/app/partners",
                parentId: 300200
            },
            {
                id: 300203,
                code: "users",
                role: ["admin:inquiry"],
                label: "sidemenu.customer.items.homepage",
                link: "/inquiry/home/users",
                parentId: 300200
            },
            {
                id: 300204,
                code: "webpartners",
                role: ["admin:inquiry"],
                label: "sidemenu.customer.items.webpartner",
                link: "/inquiry/home/webpartners",
                parentId: 300200
            },
            {
                id: 300205,
                code: "evidences",
                role: ["admin:inquiry"],
                label: "sidemenu.customer.items.evidence",
                link: "/inquiry/evidences",
                parentId: 300200
            }
        ]
    },
    {
        // 고객상담내역
        id: 300300,
        code: "counsel",
        role: ["admin:counsel"],
        label: "sidemenu.customer.items.counsel",
        icon: "bx-message-square-dots",
        link: "/counsel"
    },
    {
        // 운영
        id: 400000,
        code: "manage",
        role: ["admin:bbs", "admin:push-sms", "admin:points", "admin:coupons", "admin:payments"],
        label: "sidemenu.manage.text",
        link: "/bbs/notice",
        isTitle: true
    },
    {
        // 게시판
        id: 400100,
        code: "bbs",
        role: ["admin:bbs"],
        label: "sidemenu.board.text",
        icon: "bx-edit-alt",
        link: "/bbs/notice",
        subItems: [
            {
                id: 400101,
                code: "notice",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.notice",
                link: "/bbs/notice",
                parentId: 400100
            },
            {
                id: 400102,
                code: "qna",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.qna",
                link: "/bbs/qna",
                parentId: 400100
            },
            {
                id: 400103,
                code: "guide",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.guide",
                link: "/bbs/guide",
                parentId: 400100
            },
            {
                id: 400104,
                code: "news",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.news",
                link: "/bbs/news",
                parentId: 400100
            },
            {
                id: 400105,
                code: "event",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.event",
                link: "/bbs/event",
                parentId: 400100
            },
            {
                id: 400106,
                code: "win",
                role: ["admin:bbs"],
                label: "sidemenu.board.items.win",
                link: "/bbs/win",
                parentId: 400100
            }
        ]
    },
    {
        // 메시지
        id: 400200,
        code: "messages",
        role: ["admin:push-sms"],
        label: "sidemenu.messages.text",
        icon: "bx-bell",
        link: "/messages",
        subItems: [
            {
                id: 400202,
                code: "push",
                role: ["admin:push-sms"],
                label: "sidemenu.messages.items.push",
                link: "/messages/push",
                parentId: 400200
            },
            {
                id: 400203,
                code: "sms",
                role: ["admin:push-sms"],
                label: "sidemenu.messages.items.sms",
                link: "/messages/sms",
                parentId: 400200
            },
            {
                id: 400201,
                code: "send",
                role: ["admin:push-sms"],
                label: "sidemenu.messages.items.send",
                link: "/messages",
                parentId: 400200
            }
        ]
    },
    {
        // 포인트
        id: 400300,
        code: "point",
        role: ["admin:points"],
        label: "sidemenu.point.text",
        icon: "bxl-product-hunt",
        link: "/point",
        subItems: [
            {
                id: 400301,
                code: "history",
                role: ["admin:points"],
                label: "sidemenu.point.items.history",
                link: "/point",
                parentId: 400300
            },
            {
                id: 400302,
                code: "give",
                role: ["admin:points"],
                label: "sidemenu.point.items.give",
                link: "/point/give",
                parentId: 400300
            }
        ]
    },
/*
        {
            // 쿠폰
            id: 400400,
            code: "coupon",
            role: ["admin:coupons"],
            label: "sidemenu.coupon.text",
            icon: "bxs-coupon",
            link: "/point",
            subItems: [
                {
                    id: 400401,
                    code: "master",
                    role: ["admin:coupons"],
                    label: "sidemenu.coupon.items.master",
                    link: "/point",
                    parentId: 400400
                },
                {
                    id: 400402,
                    code: "user",
                    role: ["admin:coupons"],
                    label: "sidemenu.coupon.items.user",
                    link: "/point/user",
                    parentId: 400400
                }
            ]
        },
        {
            // 결제
            id: 400500,
            code: "payment",
            role: ["admin:payments"],
            label: "sidemenu.payment.text",
            icon: "bxs-dollar-circle",
            link: "/payment",
            subItems: [
                {
                    id: 400501,
                    code: "list",
                    role: ["admin:payments"],
                    label: "sidemenu.payment.items.list",
                    link: "/payment",
                    parentId: 400500
                }
            ]
        },
*/
    {
        // 설정
        id: 500000,
        code: "etc",
        role: ["admin:stats", "admin:setting", "admin:contents", "admin:banners"],
        label: "sidemenu.etc.text",
        link: "/stgats",
        isTitle: true
    },
/*
    {
        // 통계
        id: 500100,
        code: "stats",
        role: ["admin:stats"],
        label: "sidemenu.statistics.text",
        icon: "bx-bar-chart-alt-2",
        link: "/stats"
    },
*/
    {
        // 설정
        id: 500200,
        code: "setting",
        role: ["admin:setting", "admin:contents", "admin:banners"],
        label: "sidemenu.setting.text",
        icon: "bx-cog",
        link: "/setting/terms",
        subItems: [
            {
                id: 500201,
                code: "terms",
                role: ["admin:contents"],
                label: "sidemenu.setting.items.terms",
                link: "/setting/terms",
                parentId: 500200
            },
            {
                id: 500202,
                code: "banner",
                role: ["admin:banners"],
                label: "sidemenu.setting.items.banner",
                link: "/setting/banners/banner",
                parentId: 500200
            },
/*
            {
                id: 500203,
                code: "group",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.group",
                link: "/setting/group",
                parentId: 500200
            },
            {
                id: 500204,
                code: "area",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.area",
                link: "/setting/area",
                parentId: 500200
            },
 */
            {
                id: 500205,
                code: "notice",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.notice",
                link: "/setting/banners/notice",
                parentId: 500200
            },
            {
                id: 500206,
                code: "charge",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.charge",
                link: "/setting/charge",
                parentId: 500200
            },
            {
                id: 500207,
                code: "seo",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.seo",
                link: "/setting/seo",
                parentId: 500200
            },
            {
                id: 500208,
                code: "version",
                role: ["admin:setting"],
                label: "sidemenu.setting.items.version",
                link: "/setting/version",
                parentId: 500200
            },
        ]
    },
];