import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const appErrorService = {
    list,
    detail,
    store,
    statusUpdate,
    erase,
};

// 고객대응 > 앱문제신고
function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/app/errors?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 앱문제신고 > 상세
function detail(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };

    let url = getAdminUrl() + `/app/error/` + uid
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 앱문제신고 > 답변
function store(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/app/error/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 앱문제신고 > 답변
function statusUpdate(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/app/error/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 고객대응 > 앱문제신고 > 삭제
function erase(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };

    let url = getAdminUrl() + `/app/error/delete`
    return fetch(url, requestOptions).then(handleResponse);
}

