import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const logService = {
    family,
    accept,
    event,
    home,
    zone,
    chat,
    help,
    connect,
    location,
};

// 회원 > 조회 > 관계목록
function family(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/familys?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 요청/승인
function accept(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/accepts?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 이벤트
function event(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/events?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 안심귀가
function home(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/homes?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 안심존
function zone(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/zones?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 채팅
function chat(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/chats?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 구조요청
function help(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/helps?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 접속기록
function connect(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/log/user?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

// 회원 > 조회 > 위치열람기록
function location(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/log/location?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

