import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapState('apiAuth', {
    currentUser: (state) => state.user,
  }),
  ...mapGetters('apiAuth', ['loggedIn']),
}

export const InqueryNewCountComputed = {
  ...mapState('apiInquerys', {
    inqueryNewCount: (state) => state.inqueryNewCount,
  }),
}

export const layoutComputed = {
  ...mapState('layout', {
/*
    layoutType: (state) => state.layoutType,
    leftSidebarType: (state) => state.leftSidebarType,
    layoutWidth: (state) => state.layoutWidth,
    topbar: (state) => state.topbar,
*/
    loader: (state) => state.loader,
    menuId: (state) => state.menuId
  })
}

// export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword'])

export const layoutMethods = mapActions('layout', [/*'changeLayoutType', 'changeLayoutWidth', 'changeLeftSidebarType', 'changeTopbar', */'changeLoaderValue', 'changeMenu'])

export const apiAuthMethods = mapActions('apiAuth', ['login', 'logout', 'authCodeSend', 'authCodeVerify'])
export const apiUserMethods = mapActions('apiUsers', ['list', 'create', 'family', 'zones', 'points', 'recommends', 'recommendReset', 'memoChange', 'authSend', 'authComplete', 'store', 'changePassword', 'changeMyPassword', 'signout', 'restore', 'sendSearch', 'locations', 'familyZones'])
export const apiLogMethods = mapActions('apiLog', ['family', 'accept', 'event', 'home', 'zones', 'chat', 'connect', 'location'])
export const apiPointMethods = mapActions('apiPoint', ['list', 'store'])
export const apiHelpActiveMethods = mapActions('apiHelpActive', ['users', 'user', 'locations'])
export const apiBoardMethods = mapActions('apiBoard', ['list', 'create', 'store', 'assetDelete', 'del'])
export const apiStatisticsMethods = mapActions('apiStatistics', ['dataAll', 'dataUserSignin'])
export const apiInqueryMethods = mapActions('apiInquerys', ['newCount'])
export const apiMessagesMethods = mapActions('apiMessages', ['push', 'sms', 'send', 'store'])
export const apiAppErrorMethods = mapActions('apiAppError', ['list', 'detail', 'store', 'statusUpdate', 'erase'])
export const apiCounselMethods = mapActions('apiCounsel', ['list', 'store', 'statusUpdate', 'erase'])
export const apiInquiryMethods = mapActions('apiInquiry', ['list', 'detail', 'store', 'statusUpdate', 'erase'])
export const apiTermMethods = mapActions('apiTerm', ['list', 'load', 'store', 'sorting'])
export const apiBannerMethods = mapActions('apiBanner', ['list', 'load', 'store', 'erase'])
export const apiChargeMethods = mapActions('apiCharge', ['list', 'update'])
export const apiSeoMethods = mapActions('apiSeo', ['load', 'store'])
export const apiVersionMethods = mapActions('apiVersion', ['load', 'store'])
export const apiRolesMethods = mapActions('apiRoles', ['list', 'userRoles', 'store'])
export const apiUserCounselMethods = mapActions('apiUserCounsel', ['list', 'store', 'statusUpdate', 'erase'])
export const apiMapMethods = mapActions('apiMap', ['coordsToAddress'])

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear'])

export const todoComputed = {
  ...mapState('todo', {
    todos: (state) => state.todos
  })
}
export const todoMethods = mapActions('todo', ['fetchTodos'])