import { bannerService } from '@/helpers/service/banner.service';

export const actions = {

    // 설정 > 배너 리스트
    list({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            bannerService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > 배너 > 등록/수정
    load({ dispatch }, { uid }) {
        return new Promise((resolve, reject) => {
            bannerService.load(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > 배너 > 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            bannerService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 고객대응 > 배너 > 삭제
    erase({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            bannerService.erase(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

