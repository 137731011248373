import { seoService } from '@/helpers/service/seo.service';

export const actions = {

    // 설정 > seo 가져오기
    load({ dispatch }) {
        return new Promise((resolve, reject) => {
            seoService.load()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > seo > 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            seoService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

