import { authService } from '@/helpers/service/auth.service';
// import router from '../../router/index'

const user = JSON.parse(sessionStorage.getItem('user'));
export const state = user
    ? { status: { loggeduser: true }, user }
    : { status: {}, user: null };

export const getters = {
    // Whether the user is currently logged in.
    loggedIn(state) {
        //console.log('------------------------');
        //console.log(state);
        return state.status && state.status.loggeduser
    }
}

export const actions = {

    // Logs in the user.
    login({ dispatch, commit }, { email, password }) {
        commit('loginRequest', { email });

        return new Promise((resolve, reject) => {
            authService.login(email, password)
                .then(
                    data => {
                        commit('loginSuccess', data.user);
                        // router.push('/');
                        resolve(data);
                    },
                    error => {
                        commit('loginFailure', error);
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },
    // Logout the user
    logout({ commit }) {
        authService.logout();
        commit('logout');
    },

    // Logs in the user.
    authCodeSend({ dispatch }) {

        return new Promise((resolve, reject) => {
            authService.authCodeSend()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },

    // Logs in the user.
    authCodeVerify({ dispatch }, { code }) {

        return new Promise((resolve, reject) => {
            authService.authCodeVerify( code )
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })

    },
};

export const mutations = {
    loginRequest(state, user) {
        state.status = { loggingIn: true };
        state.user = user;
    },
    loginSuccess(state, user) {
        state.status = { loggeduser: true };
        state.user = user;
    },
    loginFailure(state) {
        state.status = {};
        state.user = null;
    },
    logout(state) {
        state.status = {};
        state.user = null;
    },
};

