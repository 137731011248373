import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';

export const versionService = {
    load,
    store,
};

// 설정 > 버전관리
function load() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/version`;
    return fetch(url, requestOptions).then(handleResponse);
}

// 설정 > 버전관리 > 저장
function store(rawUrl, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + rawUrl
    return fetch(url, requestOptions).then(handleResponse);
}
