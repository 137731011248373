import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';

export const helpActiveService = {
    list,
    users,
    user,
    locations,
    helpComplete,
};

function list() {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/help/list`;
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function users(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/help/users?` + new URLSearchParams(params).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function user(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + '/help/'+uid+'/user';
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function locations(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + '/help/'+uid+'/locations';
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

function helpComplete(uid, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + '/help/'+uid+'/complete';
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

