import {apiHeader, handleResponse, apiFileHeader} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const termService = {
    list,
    load,
    store,
    sorting
};

// 세팅 >> 약관관리
function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/contents?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

// 세팅 >> 약관관리 >> 등록/수정
function load(uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/content/` + uid
    return fetch(url, requestOptions).then(handleResponse);
}

// 세팅 >> 약관관리 >> 저장
function store(params) {
    let formData = new FormData();
    
    for ( let key in params ) {
        if(params[key] instanceof Array) {
            for ( let key2 in params[key] ) {
                formData.append(key+'[]', params[key][key2]);
            }
        }
        else formData.append(key, params[key]);
    }

    const requestOptions = {
        method: 'POST',
        headers: apiFileHeader(),
        body: formData
    };
    let url = getAdminUrl() + `/contents/store`
    return fetch(url, requestOptions).then(handleResponse);
}

// 세팅 >> 약관관리 >> 정렬저장
function sorting(uid, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/content/`+uid+`/sorting`
    
    return fetch(url, requestOptions).then(handleResponse);
}