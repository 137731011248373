import {rolesService} from '@/helpers/service/roles.service';

export const actions = {

    // 관리자 권한 > 권한 코드목록
    list({dispatch}) {
        return new Promise((resolve, reject) => {
            rolesService.list()
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 관리자 권한 > 회원 권한목록
    userRoles({dispatch}, uid) {
        return new Promise((resolve, reject) => {
            rolesService.userRoles(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },

    // 관리자 권한 > 권한 저장
    store({dispatch}, params) {
        return new Promise((resolve, reject) => {
            rolesService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, {root: true});
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {};

