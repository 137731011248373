import {apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const helpService = {
    list,
};

function list(params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/helps?` + new URLSearchParams(generateDataTableParams(params)).toString();
    console.log(url);
    return fetch(url, requestOptions).then(handleResponse);
}

