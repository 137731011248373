import { termService } from '@/helpers/service/term.service';

export const actions = {

    // 설정 > 약관관리 리스트
    list({ dispatch }, { params }) {
        return new Promise((resolve, reject) => {
            termService.list(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > 약관관리 > 등록/수정
    load({ dispatch }, { uid }) {
        return new Promise((resolve, reject) => {
            termService.load(uid)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    // 설정 > 약관관리 > 저장
    store({ dispatch }, params) {
        return new Promise((resolve, reject) => {
            termService.store(params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },

    sorting({ dispatch }, {uid, params}) {
        return new Promise((resolve, reject) => {
            termService.sorting(uid, params)
                .then(
                    data => {
                        resolve(data);
                    },
                    error => {
                        dispatch('notification/error', error, { root: true });
                        reject(error);
                    }
                );
        })
    },
};

export const mutations = {

};

