import {apiFileHeader, apiHeader, handleResponse} from '../api-header';
import {getAdminUrl} from '../api-config';
import {generateDataTableParams} from "@/helpers/datatable";

export const boardService = {
    list,
    create,
    store, 
    assetDelete,
    del,
};

function list(type, params) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/bbs/` + type + `?` + new URLSearchParams(generateDataTableParams(params)).toString();
    return fetch(url, requestOptions).then(handleResponse);
}

function create(code, uid) {
    const requestOptions = {
        method: 'GET',
        headers: apiHeader(),
    };
    let url = getAdminUrl() + `/bbs/` + code + `/` + uid
    return fetch(url, requestOptions).then(handleResponse);
}

function store(params) {
    let formData = new FormData();
    for ( let key in params ) {
        if(params[key] instanceof Array) {
            for ( let key2 in params[key] ) {
                formData.append(key+'[]', params[key][key2]);
            }
        }
        else formData.append(key, params[key]);
    }

    const requestOptions = {
        method: 'POST',
        headers: apiFileHeader(),
        body: formData
    };
    let url = getAdminUrl() + `/bbs/store`
    return fetch(url, requestOptions).then(handleResponse);
}

function assetDelete(params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/bbs/assets/delete`
    console.log(url)
    return fetch(url, requestOptions).then(handleResponse);
}

function del(code, params) {
    const requestOptions = {
        method: 'POST',
        headers: apiHeader(),
        body: JSON.stringify(params)
    };
    let url = getAdminUrl() + `/bbs/` + code + `/delete`
    return fetch(url, requestOptions).then(handleResponse);
}